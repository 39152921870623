export const keywords: string[] = [
  // https://www.ibm.com/docs/en/db2/11.5?topic=sql-reserved-schema-names-reserved-words
  'ACTIVATE',
  'ADD',
  'AFTER',
  'ALIAS',
  'ALL',
  'ALLOCATE',
  'ALLOW',
  'ALTER',
  'AND',
  'ANY',
  'AS',
  'ASENSITIVE',
  'ASSOCIATE',
  'ASUTIME',
  'AT',
  'ATTRIBUTES',
  'AUDIT',
  'AUTHORIZATION',
  'AUX',
  'AUXILIARY',
  'BEFORE',
  'BEGIN',
  'BETWEEN',
  'BINARY',
  'BUFFERPOOL',
  'BY',
  'CACHE',
  'CALL',
  'CALLED',
  'CAPTURE',
  'CARDINALITY',
  'CASCADED',
  'CASE',
  'CAST',
  'CHECK',
  'CLONE',
  'CLOSE',
  'CLUSTER',
  'COLLECTION',
  'COLLID',
  'COLUMN',
  'COMMENT',
  'COMMIT',
  'CONCAT',
  'CONDITION',
  'CONNECT',
  'CONNECTION',
  'CONSTRAINT',
  'CONTAINS',
  'CONTINUE',
  'COUNT',
  'COUNT_BIG',
  'CREATE',
  'CROSS',
  'CURRENT',
  'CURRENT_DATE',
  'CURRENT_LC_CTYPE',
  'CURRENT_PATH',
  'CURRENT_SCHEMA',
  'CURRENT_SERVER',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_TIMEZONE',
  'CURRENT_USER',
  'CURSOR',
  'CYCLE',
  'DATA',
  'DATABASE',
  'DATAPARTITIONNAME',
  'DATAPARTITIONNUM',
  'DAY',
  'DAYS',
  'DB2GENERAL',
  'DB2GENRL',
  'DB2SQL',
  'DBINFO',
  'DBPARTITIONNAME',
  'DBPARTITIONNUM',
  'DEALLOCATE',
  'DECLARE',
  'DEFAULT',
  'DEFAULTS',
  'DEFINITION',
  'DELETE',
  'DENSERANK',
  'DENSE_RANK',
  'DESCRIBE',
  'DESCRIPTOR',
  'DETERMINISTIC',
  'DIAGNOSTICS',
  'DISABLE',
  'DISALLOW',
  'DISCONNECT',
  'DISTINCT',
  'DO',
  'DOCUMENT',
  'DROP',
  'DSSIZE',
  'DYNAMIC',
  'EACH',
  'EDITPROC',
  'ELSE',
  'ELSEIF',
  'ENABLE',
  'ENCODING',
  'ENCRYPTION',
  'END',
  'END-EXEC',
  'ENDING',
  'ERASE',
  'ESCAPE',
  'EVERY',
  'EXCEPT',
  'EXCEPTION',
  'EXCLUDING',
  'EXCLUSIVE',
  'EXECUTE',
  'EXISTS',
  'EXIT',
  'EXPLAIN',
  'EXTENDED',
  'EXTERNAL',
  'EXTRACT',
  'FENCED',
  'FETCH',
  'FIELDPROC',
  'FILE',
  'FINAL',
  'FIRST1',
  'FOR',
  'FOREIGN',
  'FREE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GENERAL',
  'GENERATED',
  'GET',
  'GLOBAL',
  'GO',
  'GOTO',
  'GRANT',
  'GRAPHIC',
  'GROUP',
  'HANDLER',
  'HASH',
  'HASHED_VALUE',
  'HAVING',
  'HINT',
  'HOLD',
  'HOUR',
  'HOURS',
  'IDENTITY',
  'IF',
  'IMMEDIATE',
  'IMPORT',
  'IN',
  'INCLUDING',
  'INCLUSIVE',
  'INCREMENT',
  'INDEX',
  'INDICATOR',
  'INDICATORS',
  'INF',
  'INFINITY',
  'INHERIT',
  'INNER',
  'INOUT',
  'INSENSITIVE',
  'INSERT',
  'INTEGRITY',
  'INTERSECT',
  'INTO',
  'IS',
  'ISNULL',
  'ISOBID',
  'ISOLATION',
  'ITERATE',
  'JAR',
  'JAVA',
  'JOIN',
  'KEEP',
  'KEY',
  'LABEL',
  'LANGUAGE',
  'LAST3',
  'LATERAL',
  'LC_CTYPE',
  'LEAVE',
  'LEFT',
  'LIKE',
  'LIMIT',
  'LINKTYPE',
  'LOCAL',
  'LOCALDATE',
  'LOCALE',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'LOCATOR',
  'LOCATORS',
  'LOCK',
  'LOCKMAX',
  'LOCKSIZE',
  'LOOP',
  'MAINTAINED',
  'MATERIALIZED',
  'MAXVALUE',
  'MICROSECOND',
  'MICROSECONDS',
  'MINUTE',
  'MINUTES',
  'MINVALUE',
  'MODE',
  'MODIFIES',
  'MONTH',
  'MONTHS',
  'NAN',
  'NEW',
  'NEW_TABLE',
  'NEXTVAL',
  'NO',
  'NOCACHE',
  'NOCYCLE',
  'NODENAME',
  'NODENUMBER',
  'NOMAXVALUE',
  'NOMINVALUE',
  'NONE',
  'NOORDER',
  'NORMALIZED',
  'NOT2',
  'NOTNULL',
  'NULL',
  'NULLS',
  'NUMPARTS',
  'OBID',
  'OF',
  'OFF',
  'OFFSET',
  'OLD',
  'OLD_TABLE',
  'ON',
  'OPEN',
  'OPTIMIZATION',
  'OPTIMIZE',
  'OPTION',
  'OR',
  'ORDER',
  'OUT',
  'OUTER',
  'OVER',
  'OVERRIDING',
  'PACKAGE',
  'PADDED',
  'PAGESIZE',
  'PARAMETER',
  'PART',
  'PARTITION',
  'PARTITIONED',
  'PARTITIONING',
  'PARTITIONS',
  'PASSWORD',
  'PATH',
  'PERCENT',
  'PIECESIZE',
  'PLAN',
  'POSITION',
  'PRECISION',
  'PREPARE',
  'PREVVAL',
  'PRIMARY',
  'PRIQTY',
  'PRIVILEGES',
  'PROCEDURE',
  'PROGRAM',
  'PSID',
  'PUBLIC',
  'QUERY',
  'QUERYNO',
  'RANGE',
  'RANK',
  'READ',
  'READS',
  'RECOVERY',
  'REFERENCES',
  'REFERENCING',
  'REFRESH',
  'RELEASE',
  'RENAME',
  'REPEAT',
  'RESET',
  'RESIGNAL',
  'RESTART',
  'RESTRICT',
  'RESULT',
  'RESULT_SET_LOCATOR',
  'RETURN',
  'RETURNS',
  'REVOKE',
  'RIGHT',
  'ROLE',
  'ROLLBACK',
  'ROUND_CEILING',
  'ROUND_DOWN',
  'ROUND_FLOOR',
  'ROUND_HALF_DOWN',
  'ROUND_HALF_EVEN',
  'ROUND_HALF_UP',
  'ROUND_UP',
  'ROUTINE',
  'ROW',
  'ROWNUMBER',
  'ROWS',
  'ROWSET',
  'ROW_NUMBER',
  'RRN',
  'RUN',
  'SAVEPOINT',
  'SCHEMA',
  'SCRATCHPAD',
  'SCROLL',
  'SEARCH',
  'SECOND',
  'SECONDS',
  'SECQTY',
  'SECURITY',
  'SELECT',
  'SENSITIVE',
  'SEQUENCE',
  'SESSION',
  'SESSION_USER',
  'SET',
  'SIGNAL',
  'SIMPLE',
  'SNAN',
  'SOME',
  'SOURCE',
  'SPECIFIC',
  'SQL',
  'SQLID',
  'STACKED',
  'STANDARD',
  'START',
  'STARTING',
  'STATEMENT',
  'STATIC',
  'STATMENT',
  'STAY',
  'STOGROUP',
  'STORES',
  'STYLE',
  'SUBSTRING',
  'SUMMARY',
  'SYNONYM',
  'SYSFUN',
  'SYSIBM',
  'SYSPROC',
  'SYSTEM',
  'SYSTEM_USER',
  'TABLE',
  'TABLESPACE',
  'THEN',
  'TO',
  'TRANSACTION',
  'TRIGGER',
  'TRIM',
  'TRUNCATE',
  'TYPE',
  'UNDO',
  'UNION',
  'UNIQUE',
  'UNTIL',
  'UPDATE',
  'USAGE',
  'USER',
  'USING',
  'VALIDPROC',
  'VALUE',
  'VALUES',
  'VARIABLE',
  'VARIANT',
  'VCAT',
  'VERSION',
  'VIEW',
  'VOLATILE',
  'VOLUMES',
  'WHEN',
  'WHENEVER',
  'WHERE',
  'WHILE',
  'WITH',
  'WITHOUT',
  'WLM',
  'WRITE',
  'XMLELEMENT',
  'XMLEXISTS',
  'XMLNAMESPACES',
  'YEAR',
  'YEARS',
];

export const dataTypes: string[] = [
  // https://www.ibm.com/docs/en/db2-for-zos/12?topic=columns-data-types
  'ARRAY',
  'BIGINT',
  'BINARY',
  'BLOB',
  'BOOLEAN',
  'CCSID',
  'CHAR',
  'CHARACTER',
  'CLOB',
  'DATE',
  'DATETIME',
  'DBCLOB',
  'DEC',
  'DECIMAL',
  'DOUBLE',
  'DOUBLE PRECISION',
  'FLOAT',
  'FLOAT4',
  'FLOAT8',
  'GRAPHIC',
  'INT',
  'INT2',
  'INT4',
  'INT8',
  'INTEGER',
  'INTERVAL',
  'LONG VARCHAR',
  'LONG VARGRAPHIC',
  'NCHAR',
  'NCHR',
  'NCLOB',
  'NVARCHAR',
  'NUMERIC',
  'SMALLINT',
  'REAL',
  'TIME',
  'TIMESTAMP',
  'VARBINARY',
  'VARCHAR',
  'VARGRAPHIC',
];

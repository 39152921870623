export const keywords: string[] = [
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#reserved-word
  'ALL',
  'ALLOCATE',
  'ALTER',
  'ANY', // <- moved over from functions
  'ARE',
  'AS',
  'ASC', // Not reserved in SQL-2008, but commonly reserved in most dialects
  'ASENSITIVE',
  'ASYMMETRIC',
  'AT',
  'ATOMIC',
  'AUTHORIZATION',
  'BEGIN',
  'BETWEEN',
  'BOTH',
  'BY',
  'CALL',
  'CALLED',
  'CASCADED',
  'CAST',
  'CHECK',
  'CLOSE',
  'COALESCE',
  'COLLATE',
  'COLUMN',
  'COMMIT',
  'CONDITION',
  'CONNECT',
  'CONSTRAINT',
  'CORRESPONDING',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'CURRENT_CATALOG',
  'CURRENT_DEFAULT_TRANSFORM_GROUP',
  'CURRENT_PATH',
  'CURRENT_ROLE',
  'CURRENT_SCHEMA',
  'CURRENT_TRANSFORM_GROUP_FOR_TYPE',
  'CURRENT_USER',
  'CURSOR',
  'CYCLE',
  'DEALLOCATE',
  'DAY',
  'DECLARE',
  'DEFAULT',
  'DELETE',
  'DEREF',
  'DESC', // Not reserved in SQL-2008, but commonly reserved in most dialects
  'DESCRIBE',
  'DETERMINISTIC',
  'DISCONNECT',
  'DISTINCT',
  'DROP',
  'DYNAMIC',
  'EACH',
  'ELEMENT',
  'END-EXEC',
  'ESCAPE',
  'EVERY', // <- moved over from functions
  'EXCEPT',
  'EXEC',
  'EXECUTE',
  'EXISTS',
  'EXTERNAL',
  'FALSE',
  'FETCH',
  'FILTER',
  'FOR',
  'FOREIGN',
  'FREE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GET',
  'GLOBAL',
  'GRANT',
  'GROUP',
  'HAVING',
  'HOLD',
  'HOUR',
  'IDENTITY',
  'IN',
  'INDICATOR',
  'INNER',
  'INOUT',
  'INSENSITIVE',
  'INSERT',
  'INTERSECT',
  'INTO',
  'IS',
  'LANGUAGE',
  'LARGE',
  'LATERAL',
  'LEADING',
  'LEFT',
  'LIKE',
  'LIKE_REGEX',
  'LOCAL',
  'MATCH',
  'MEMBER',
  'MERGE',
  'METHOD',
  'MINUTE',
  'MODIFIES',
  'MODULE',
  'MONTH',
  'NATURAL',
  'NEW',
  'NO',
  'NONE',
  'NOT',
  'NULL',
  'NULLIF',
  'OF',
  'OLD',
  'ON',
  'ONLY',
  'OPEN',
  'ORDER',
  'OUT',
  'OUTER',
  'OVER',
  'OVERLAPS',
  'PARAMETER',
  'PARTITION',
  'PRECISION',
  'PREPARE',
  'PRIMARY',
  'PROCEDURE',
  'RANGE',
  'READS',
  'REAL',
  'RECURSIVE',
  'REF',
  'REFERENCES',
  'REFERENCING',
  'RELEASE',
  'RESULT',
  'RETURN',
  'RETURNS',
  'REVOKE',
  'RIGHT',
  'ROLLBACK',
  'ROLLUP',
  'ROW',
  'ROWS',
  'SAVEPOINT',
  'SCOPE',
  'SCROLL',
  'SEARCH',
  'SECOND',
  'SELECT',
  'SENSITIVE',
  'SESSION_USER',
  'SET',
  'SIMILAR',
  'SOME', // <- moved over from functions
  'SPECIFIC',
  'SQL',
  'SQLEXCEPTION',
  'SQLSTATE',
  'SQLWARNING',
  'START',
  'STATIC',
  'SUBMULTISET',
  'SYMMETRIC',
  'SYSTEM',
  'SYSTEM_USER',
  'TABLE',
  'TABLESAMPLE',
  'THEN',
  'TIMEZONE_HOUR',
  'TIMEZONE_MINUTE',
  'TO',
  'TRAILING',
  'TRANSLATION',
  'TREAT',
  'TRIGGER',
  'TRUE',
  'UESCAPE',
  'UNION',
  'UNIQUE',
  'UNKNOWN',
  'UNNEST',
  'UPDATE',
  'USER',
  'USING',
  'VALUE',
  'VALUES',
  'WHENEVER',
  'WINDOW',
  'WITHIN',
  'WITHOUT',
  'YEAR',
];

export const dataTypes: string[] = [
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_1_data_type
  'ARRAY',
  'BIGINT',
  'BINARY LARGE OBJECT',
  'BINARY VARYING',
  'BINARY',
  'BLOB',
  'BOOLEAN',
  'CHAR LARGE OBJECT',
  'CHAR VARYING',
  'CHAR',
  'CHARACTER LARGE OBJECT',
  'CHARACTER VARYING',
  'CHARACTER',
  'CLOB',
  'DATE',
  'DEC',
  'DECIMAL',
  'DOUBLE',
  'FLOAT',
  'INT',
  'INTEGER',
  'INTERVAL',
  'MULTISET',
  'NATIONAL CHAR VARYING',
  'NATIONAL CHAR',
  'NATIONAL CHARACTER LARGE OBJECT',
  'NATIONAL CHARACTER VARYING',
  'NATIONAL CHARACTER',
  'NCHAR LARGE OBJECT',
  'NCHAR VARYING',
  'NCHAR',
  'NCLOB',
  'NUMERIC',
  'SMALLINT',
  'TIME',
  'TIMESTAMP',
  'VARBINARY',
  'VARCHAR',
];

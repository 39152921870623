export const functions: string[] = [
  // https://cwiki.apache.org/confluence/display/Hive/LanguageManual+UDF
  // math
  'ABS',
  'ACOS',
  'ASIN',
  'ATAN',
  'BIN',
  'BROUND',
  'CBRT',
  'CEIL',
  'CEILING',
  'CONV',
  'COS',
  'DEGREES',
  // 'E',
  'EXP',
  'FACTORIAL',
  'FLOOR',
  'GREATEST',
  'HEX',
  'LEAST',
  'LN',
  'LOG',
  'LOG10',
  'LOG2',
  'NEGATIVE',
  'PI',
  'PMOD',
  'POSITIVE',
  'POW',
  'POWER',
  'RADIANS',
  'RAND',
  'ROUND',
  'SHIFTLEFT',
  'SHIFTRIGHT',
  'SHIFTRIGHTUNSIGNED',
  'SIGN',
  'SIN',
  'SQRT',
  'TAN',
  'UNHEX',
  'WIDTH_BUCKET',

  // array
  'ARRAY_CONTAINS',
  'MAP_KEYS',
  'MAP_VALUES',
  'SIZE',
  'SORT_ARRAY',

  // conversion
  'BINARY',
  'CAST',

  // date
  'ADD_MONTHS',
  'DATE',
  'DATE_ADD',
  'DATE_FORMAT',
  'DATE_SUB',
  'DATEDIFF',
  'DAY',
  'DAYNAME',
  'DAYOFMONTH',
  'DAYOFYEAR',
  'EXTRACT',
  'FROM_UNIXTIME',
  'FROM_UTC_TIMESTAMP',
  'HOUR',
  'LAST_DAY',
  'MINUTE',
  'MONTH',
  'MONTHS_BETWEEN',
  'NEXT_DAY',
  'QUARTER',
  'SECOND',
  'TIMESTAMP',
  'TO_DATE',
  'TO_UTC_TIMESTAMP',
  'TRUNC',
  'UNIX_TIMESTAMP',
  'WEEKOFYEAR',
  'YEAR',

  // conditional
  'ASSERT_TRUE',
  'COALESCE',
  'IF',
  'ISNOTNULL',
  'ISNULL',
  'NULLIF',
  'NVL',

  // string
  'ASCII',
  'BASE64',
  'CHARACTER_LENGTH',
  'CHR',
  'CONCAT',
  'CONCAT_WS',
  'CONTEXT_NGRAMS',
  'DECODE',
  'ELT',
  'ENCODE',
  'FIELD',
  'FIND_IN_SET',
  'FORMAT_NUMBER',
  'GET_JSON_OBJECT',
  'IN_FILE',
  'INITCAP',
  'INSTR',
  'LCASE',
  'LENGTH',
  'LEVENSHTEIN',
  'LOCATE',
  'LOWER',
  'LPAD',
  'LTRIM',
  'NGRAMS',
  'OCTET_LENGTH',
  'PARSE_URL',
  'PRINTF',
  'QUOTE',
  'REGEXP_EXTRACT',
  'REGEXP_REPLACE',
  'REPEAT',
  'REVERSE',
  'RPAD',
  'RTRIM',
  'SENTENCES',
  'SOUNDEX',
  'SPACE',
  'SPLIT',
  'STR_TO_MAP',
  'SUBSTR',
  'SUBSTRING',
  'TRANSLATE',
  'TRIM',
  'UCASE',
  'UNBASE64',
  'UPPER',

  // masking
  'MASK',
  'MASK_FIRST_N',
  'MASK_HASH',
  'MASK_LAST_N',
  'MASK_SHOW_FIRST_N',
  'MASK_SHOW_LAST_N',

  // misc
  'AES_DECRYPT',
  'AES_ENCRYPT',
  'CRC32',
  'CURRENT_DATABASE',
  'CURRENT_USER',
  'HASH',
  'JAVA_METHOD',
  'LOGGED_IN_USER',
  'MD5',
  'REFLECT',
  'SHA',
  'SHA1',
  'SHA2',
  'SURROGATE_KEY',
  'VERSION',

  // aggregate
  'AVG',
  'COLLECT_LIST',
  'COLLECT_SET',
  'CORR',
  'COUNT',
  'COVAR_POP',
  'COVAR_SAMP',
  'HISTOGRAM_NUMERIC',
  'MAX',
  'MIN',
  'NTILE',
  'PERCENTILE',
  'PERCENTILE_APPROX',
  'REGR_AVGX',
  'REGR_AVGY',
  'REGR_COUNT',
  'REGR_INTERCEPT',
  'REGR_R2',
  'REGR_SLOPE',
  'REGR_SXX',
  'REGR_SXY',
  'REGR_SYY',
  'STDDEV_POP',
  'STDDEV_SAMP',
  'SUM',
  'VAR_POP',
  'VAR_SAMP',
  'VARIANCE',

  // table
  'EXPLODE',
  'INLINE',
  'JSON_TUPLE',
  'PARSE_URL_TUPLE',
  'POSEXPLODE',
  'STACK',

  // https://cwiki.apache.org/confluence/display/Hive/LanguageManual+WindowingAndAnalytics
  'LEAD',
  'LAG',
  'FIRST_VALUE',
  'LAST_VALUE',
  'RANK',
  'ROW_NUMBER',
  'DENSE_RANK',
  'CUME_DIST',
  'PERCENT_RANK',
  'NTILE',
];

export const keywords: string[] = [
  // https://docs.oracle.com/cd/B19306_01/appdev.102/b14261/reservewords.htm
  // 'A',
  'ADD',
  'AGENT',
  'AGGREGATE',
  'ALL',
  'ALTER',
  'AND',
  'ANY',
  'ARROW',
  'AS',
  'ASC',
  'AT',
  'ATTRIBUTE',
  'AUTHID',
  'AVG',
  'BEGIN',
  'BETWEEN',
  'BLOCK',
  'BODY',
  'BOTH',
  'BOUND',
  'BULK',
  'BY',
  'BYTE',
  // 'C',
  'CALL',
  'CALLING',
  'CASCADE',
  'CASE',
  'CHARSET',
  'CHARSETFORM',
  'CHARSETID',
  'CHECK',
  'CLOSE',
  'CLUSTER',
  'CLUSTERS',
  'COLAUTH',
  'COLLECT',
  'COLUMNS',
  'COMMENT',
  'COMMIT',
  'COMMITTED',
  'COMPILED',
  'COMPRESS',
  'CONNECT',
  'CONSTANT',
  'CONSTRUCTOR',
  'CONTEXT',
  'CONVERT',
  'COUNT',
  'CRASH',
  'CREATE',
  'CURRENT',
  'CURSOR',
  'CUSTOMDATUM',
  'DANGLING',
  'DATA',
  'DAY',
  'DECLARE',
  'DEFAULT',
  'DEFINE',
  'DELETE',
  'DESC',
  'DETERMINISTIC',
  'DISTINCT',
  'DROP',
  'DURATION',
  'ELEMENT',
  'ELSE',
  'ELSIF',
  'EMPTY',
  'END',
  'ESCAPE',
  'EXCEPT',
  'EXCEPTION',
  'EXCEPTIONS',
  'EXCLUSIVE',
  'EXECUTE',
  'EXISTS',
  'EXIT',
  'EXTERNAL',
  'FETCH',
  'FINAL',
  'FIXED',
  'FOR',
  'FORALL',
  'FORCE',
  'FORM',
  'FROM',
  'FUNCTION',
  'GENERAL',
  'GOTO',
  'GRANT',
  'GROUP',
  'HASH',
  'HAVING',
  'HEAP',
  'HIDDEN',
  'HOUR',
  'IDENTIFIED',
  'IF',
  'IMMEDIATE',
  'IN',
  'INCLUDING',
  'INDEX',
  'INDEXES',
  'INDICATOR',
  'INDICES',
  'INFINITE',
  'INSERT',
  'INSTANTIABLE',
  'INTERFACE',
  'INTERSECT',
  'INTERVAL',
  'INTO',
  'INVALIDATE',
  'IS',
  'ISOLATION',
  'JAVA',
  'LANGUAGE',
  'LARGE',
  'LEADING',
  'LENGTH',
  'LEVEL',
  'LIBRARY',
  'LIKE',
  'LIKE2',
  'LIKE4',
  'LIKEC',
  'LIMIT',
  'LIMITED',
  'LOCAL',
  'LOCK',
  'LOOP',
  'MAP',
  'MAX',
  'MAXLEN',
  'MEMBER',
  'MERGE',
  'MIN',
  'MINUS',
  'MINUTE',
  'MOD',
  'MODE',
  'MODIFY',
  'MONTH',
  'MULTISET',
  'NAME',
  'NAN',
  'NATIONAL',
  'NATIVE',
  'NEW',
  'NOCOMPRESS',
  'NOCOPY',
  'NOT',
  'NOWAIT',
  'NULL',
  'OBJECT',
  'OCICOLL',
  'OCIDATE',
  'OCIDATETIME',
  'OCIDURATION',
  'OCIINTERVAL',
  'OCILOBLOCATOR',
  'OCINUMBER',
  'OCIRAW',
  'OCIREF',
  'OCIREFCURSOR',
  'OCIROWID',
  'OCISTRING',
  'OCITYPE',
  'OF',
  'ON',
  'ONLY',
  'OPAQUE',
  'OPEN',
  'OPERATOR',
  'OPTION',
  'OR',
  'ORACLE',
  'ORADATA',
  'ORDER',
  'OVERLAPS',
  'ORGANIZATION',
  'ORLANY',
  'ORLVARY',
  'OTHERS',
  'OUT',
  'OVERRIDING',
  'PACKAGE',
  'PARALLEL_ENABLE',
  'PARAMETER',
  'PARAMETERS',
  'PARTITION',
  'PASCAL',
  'PIPE',
  'PIPELINED',
  'PRAGMA',
  'PRIOR',
  'PRIVATE',
  'PROCEDURE',
  'PUBLIC',
  'RAISE',
  'RANGE',
  'READ',
  'RECORD',
  'REF',
  'REFERENCE',
  'REM',
  'REMAINDER',
  'RENAME',
  'RESOURCE',
  'RESULT',
  'RETURN',
  'RETURNING',
  'REVERSE',
  'REVOKE',
  'ROLLBACK',
  'ROW',
  'SAMPLE',
  'SAVE',
  'SAVEPOINT',
  'SB1',
  'SB2',
  'SB4',
  'SECOND',
  'SEGMENT',
  'SELECT',
  'SELF',
  'SEPARATE',
  'SEQUENCE',
  'SERIALIZABLE',
  'SET',
  'SHARE',
  'SHORT',
  'SIZE',
  'SIZE_T',
  'SOME',
  'SPARSE',
  'SQL',
  'SQLCODE',
  'SQLDATA',
  'SQLNAME',
  'SQLSTATE',
  'STANDARD',
  'START',
  'STATIC',
  'STDDEV',
  'STORED',
  'STRING',
  'STRUCT',
  'STYLE',
  'SUBMULTISET',
  'SUBPARTITION',
  'SUBSTITUTABLE',
  'SUBTYPE',
  'SUM',
  'SYNONYM',
  'TABAUTH',
  'TABLE',
  'TDO',
  'THE',
  'THEN',
  'TIME',
  'TIMEZONE_ABBR',
  'TIMEZONE_HOUR',
  'TIMEZONE_MINUTE',
  'TIMEZONE_REGION',
  'TO',
  'TRAILING',
  'TRANSAC',
  'TRANSACTIONAL',
  'TRUSTED',
  'TYPE',
  'UB1',
  'UB2',
  'UB4',
  'UNDER',
  'UNION',
  'UNIQUE',
  'UNSIGNED',
  'UNTRUSTED',
  'UPDATE',
  'USE',
  'USING',
  'VALIST',
  'VALUE',
  'VALUES',
  'VARIABLE',
  'VARIANCE',
  'VARRAY',
  'VIEW',
  'VIEWS',
  'VOID',
  'WHEN',
  'WHERE',
  'WHILE',
  'WITH',
  'WORK',
  'WRAPPED',
  'WRITE',
  'YEAR',
  'ZONE',
];

export const dataTypes: string[] = [
  // https://www.ibm.com/docs/en/db2/10.5?topic=plsql-data-types
  'ARRAY',
  'BFILE_BASE',
  'BINARY',
  'BLOB_BASE',
  'CHAR VARYING',
  'CHAR_BASE',
  'CHAR',
  'CHARACTER VARYING',
  'CHARACTER',
  'CLOB_BASE',
  'DATE_BASE',
  'DATE',
  'DECIMAL',
  'DOUBLE',
  'FLOAT',
  'INT',
  'INTERVAL DAY',
  'INTERVAL YEAR',
  'LONG',
  'NATIONAL CHAR VARYING',
  'NATIONAL CHAR',
  'NATIONAL CHARACTER VARYING',
  'NATIONAL CHARACTER',
  'NCHAR VARYING',
  'NCHAR',
  'NCHAR',
  'NUMBER_BASE',
  'NUMBER',
  'NUMBERIC',
  'NVARCHAR',
  'PRECISION',
  'RAW',
  'TIMESTAMP',
  'UROWID',
  'VARCHAR',
  'VARCHAR2',
];

export const keywords: string[] = [
  // https://docs.aws.amazon.com/redshift/latest/dg/r_pg_keywords.html
  'AES128',
  'AES256',
  'ALL',
  'ALLOWOVERWRITE',
  'ANY',
  'AS',
  'ASC',
  'AUTHORIZATION',
  'BACKUP',
  'BETWEEN',
  'BINARY',
  'BOTH',
  'CHECK',
  'COLUMN',
  'CONSTRAINT',
  'CREATE',
  'CROSS',
  'DEFAULT',
  'DEFERRABLE',
  'DEFLATE',
  'DEFRAG',
  'DESC',
  'DISABLE',
  'DISTINCT',
  'DO',
  'ENABLE',
  'ENCODE',
  'ENCRYPT',
  'ENCRYPTION',
  'EXPLICIT',
  'FALSE',
  'FOR',
  'FOREIGN',
  'FREEZE',
  'FROM',
  'FULL',
  'GLOBALDICT256',
  'GLOBALDICT64K',
  'GROUP',
  'IDENTITY',
  'IGNORE',
  'ILIKE',
  'IN',
  'INITIALLY',
  'INNER',
  'INTO',
  'IS',
  'ISNULL',
  'LANGUAGE',
  'LEADING',
  'LIKE',
  'LIMIT',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'LUN',
  'LUNS',
  'MINUS',
  'NATURAL',
  'NEW',
  'NOT',
  'NOTNULL',
  'NULL',
  'NULLS',
  'OFF',
  'OFFLINE',
  'OFFSET',
  'OID',
  'OLD',
  'ON',
  'ONLY',
  'OPEN',
  'ORDER',
  'OUTER',
  'OVERLAPS',
  'PARALLEL',
  'PARTITION',
  'PERCENT',
  'PERMISSIONS',
  'PLACING',
  'PRIMARY',
  'RECOVER',
  'REFERENCES',
  'REJECTLOG',
  'RESORT',
  'RESPECT',
  'RESTORE',
  'SIMILAR',
  'SNAPSHOT',
  'SOME',
  'SYSTEM',
  'TABLE',
  'TAG',
  'TDES',
  'THEN',
  'TIMESTAMP',
  'TO',
  'TOP',
  'TRAILING',
  'TRUE',
  'UNIQUE',
  'USING',
  'VERBOSE',
  'WALLET',
  'WITHOUT',

  // https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-conversion.html
  'ACCEPTANYDATE',
  'ACCEPTINVCHARS',
  'BLANKSASNULL',
  'DATEFORMAT',
  'EMPTYASNULL',
  'ENCODING',
  'ESCAPE',
  'EXPLICIT_IDS',
  'FILLRECORD',
  'IGNOREBLANKLINES',
  'IGNOREHEADER',
  'REMOVEQUOTES',
  'ROUNDEC',
  'TIMEFORMAT',
  'TRIMBLANKS',
  'TRUNCATECOLUMNS',

  // https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-load.html
  'COMPROWS',
  'COMPUPDATE',
  'MAXERROR',
  'NOLOAD',
  'STATUPDATE',

  // https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html
  'FORMAT',
  'CSV',
  'DELIMITER',
  'FIXEDWIDTH',
  'SHAPEFILE',
  'AVRO',
  'JSON',
  'PARQUET',
  'ORC',

  // https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-authorization.html
  'ACCESS_KEY_ID',
  'CREDENTIALS',
  'ENCRYPTED',
  'IAM_ROLE',
  'MASTER_SYMMETRIC_KEY',
  'SECRET_ACCESS_KEY',
  'SESSION_TOKEN',

  // https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-file-compression.html
  'BZIP2',
  'GZIP',
  'LZOP',
  'ZSTD',

  // https://docs.aws.amazon.com/redshift/latest/dg/r_COPY-alphabetical-parm-list.html
  'MANIFEST',
  'READRATIO',
  'REGION',
  'SSH',

  // https://docs.aws.amazon.com/redshift/latest/dg/c_Compression_encodings.html
  'RAW',
  'AZ64',
  'BYTEDICT',
  'DELTA',
  'DELTA32K',
  'LZO',
  'MOSTLY8',
  'MOSTLY16',
  'MOSTLY32',
  'RUNLENGTH',
  'TEXT255',
  'TEXT32K',

  // misc
  // CREATE EXTERNAL SCHEMA (https://docs.aws.amazon.com/redshift/latest/dg/r_CREATE_EXTERNAL_SCHEMA.html)
  'CATALOG_ROLE',
  'SECRET_ARN',
  'EXTERNAL',
  // https://docs.aws.amazon.com/redshift/latest/dg/c_choosing_dist_sort.html
  'AUTO',
  'EVEN',
  'KEY',
  'PREDICATE', // ANALYZE | ANALYSE (https://docs.aws.amazon.com/redshift/latest/dg/r_ANALYZE.html)
  // unknown
  'COMPRESSION',

  /**
   * Other keywords not included:
   * STL: https://docs.aws.amazon.com/redshift/latest/dg/c_intro_STL_tables.html
   * SVCS: https://docs.aws.amazon.com/redshift/latest/dg/svcs_views.html
   * SVL: https://docs.aws.amazon.com/redshift/latest/dg/svl_views.html
   * SVV: https://docs.aws.amazon.com/redshift/latest/dg/svv_views.html
   */
];

export const dataTypes: string[] = [
  // https://docs.aws.amazon.com/redshift/latest/dg/r_Character_types.html#r_Character_types-text-and-bpchar-types
  'ARRAY',
  'BIGINT',
  'BPCHAR',
  'CHAR',
  'CHARACTER VARYING',
  'CHARACTER',
  'DECIMAL',
  'INT',
  'INT2',
  'INT4',
  'INT8',
  'INTEGER',
  'NCHAR',
  'NUMERIC',
  'NVARCHAR',
  'SMALLINT',
  'TEXT',
  'VARBYTE',
  'VARCHAR',
];
